<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品货号')"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品货号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    :label="$t('pageKey206', '商品类目')"
                >
                    <CascaderPicker
                        key="goodsCatId"
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                        :query-params="{shopIdNull:1}"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="pointGoodsCatId"
                    :label="$t('pageKey407', '积分类目')"
                >
                    <CascaderPicker
                        key="pointGoodsCatId"
                        :api-class="selectApi1"
                        v-model="queryFormModel.pointGoodsCatId"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopTagIdList"
                    :label="$t('pageKey327', '商品分组')"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/shopTag/select"
                        :placeholder="$t('pageKey101', '请选择')"
                        :props="{id:'id',name:'tagName'}"
                        v-model="queryFormModel.shopTagIdList"
                    />
                </el-form-item>
                <el-form-item
                    prop="taxRate"
                    :label="$t('pageKey409', '税率')"
                >
                    <el-select
                        v-model="queryFormModel.taxRate"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            key="0"
                            label="0%"
                            :value="0"
                        />
                        <el-option
                            key="5"
                            label="6.3%"
                            :value="6.3"
                        />
                        <el-option
                            key="1"
                            label="9.1%"
                            :value="9.1"
                        />
                        <el-option
                            key="2"
                            label="23.1%"
                            :value="23.1"
                        />
                        <el-option
                            key="3"
                            label="29%"
                            :value="29"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="goodsStatus"
                    :label="$t('pageKey219', '商品状态')"
                >
                    <dictionaries-picker
                        type="goodsStatus"
                        v-model="queryFormModel.goodsStatus"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    :label="$t('pageKey12', '是否可用')"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            key="1"
                            :label="$t('pageKey209', '是')"
                            value="1"
                        />
                        <el-option
                            key="2"
                            :label="$t('pageKey210', '否')"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAddSelect"
                    >
                        {{ $t('pageKey228', '商品代理') }}
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    :label="$t('pageKey215', '商品图片')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.mainImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品货号')"
                    min-width="60"
                />
                <el-table-column
                    prop="goodsCatNamePath"
                    :label="$t('pageKey206', '商品类目')"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsStatusName"
                    :label="$t('pageKey219', '商品状态')"
                    min-width="50"
                />
                <el-table-column
                    prop="price"
                    :label="$t('pageKey243', '价格')"
                    min-width="60"
                />
                <!--                <el-table-column
prop="shareIncomeRate"
label="分佣比例"
min-width="60"
/>-->
                <el-table-column
                    prop="shopIncomeRate"
                    :label="$t('pageKey410', '商户级分佣比例')"
                    min-width="60"
                />
                <el-table-column
                    prop="totalShowStock"
                    :label="$t('pageKey218', '库存')"
                    min-width="60"
                >
                    <template slot-scope="scope">
                        {{ scope.row.totalShowStock }}({{ scope.row.totalRealShowStock }})
                    </template>
                </el-table-column>
                <el-table-column
                    prop="taxRate"
                    :label="$t('pageKey409', '税率')"
                    min-width="60"
                />
                <el-table-column
                    :label="$t('pageKey12', '是否可用')"
                    width="85"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @change="onEdit(scope.row)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey11', '排序')"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            v-model.number="scope.row.orderNum"
                            @change="onEdit(scope.row)"
                            size="small"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goodsAgent/goodsAgent/edit/${scope.row.goodsId}`)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            v-if="(scope.row.paymentMethod & 1) !== 0"
                            @click="onShowQrCode(scope.row)"
                        >
                            {{ $t('pageKey411', '生成二维码') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--选择商品-->
        <el-dialog
            :title="$t('pageKey328', '选择商品')"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品货号')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品货号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    :label="$t('pageKey206', '商品类目')"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="selectDialog.queryFormModel.goodsCatId"
                        :query-params="{shopIdNull:1}"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    :label="$t('pageKey412', '库存范围')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.quotationsStockMin"
                        style="width: 80px"
                    />
                    -
                    <el-input
                        v-model="selectDialog.queryFormModel.quotationsStockMax"
                        style="width: 80px"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
                <el-form-item
                    prop="quotationsStockMin"
                />
                <el-form-item
                    prop="quotationsStockMax"
                />
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                >
                    <el-table-column
                        :label="$t('pageKey215', '商品图片')"
                        width="120"
                    >
                        <template slot-scope="scope">
                            <ImageList
                                :data="scope.row.mainImageUrl"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        :label="$t('pageKey204', '商品名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        :label="$t('pageKey202', '商品货号')"
                        min-width="55"
                    />
                    <el-table-column
                        prop="goodsCatName"
                        :label="$t('pageKey206', '商品类目')"
                        min-width="50"
                    />
                    <el-table-column
                        prop="quotationsStock"
                        :label="$t('pageKey218', '库存')"
                        width="70"
                    />
                    <el-table-column
                        prop="goodsStatusName"
                        :label="$t('pageKey219', '商品状态')"
                        width="90"
                    />
                    <el-table-column
                        fixed="right"
                        :label="$t('pageKey13', '操作')"
                        width="110"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="$router.push(`/gd/goodsAgent/goodsAgent/edit/${scope.row.id}`)"
                            >
                                {{ $t('pageKey228', '商品代理') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            :title="qrCodeDialog.formModel.id ? $t('pageKey16', '编辑') : $t('pageKey15', '新增')"
            center
            width="800px"
            :visible.sync="qrCodeDialog.isVisible"
            @closed="onQrCodeDialogCancel"
        >
            <el-form
                ref="qrCodeForm"
                size="small"
                :model="qrCodeDialog.formModel"
                :rules="qrCodeDialog.formRules"
            >
                <el-form-item
                    prop="deviceId"
                    :label="$t('pageKey413', '设备')"
                    label-width="6em"
                >
                    <RemoteSelect
                        filterable
                        clearable
                        remote="/sp/device/data"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'selectName'}"
                        v-model="qrCodeDialog.formModel.deviceId"
                    />
                    <el-button
                        class="ma-l"
                        type="primary"
                        size="small"
                        @click="onQrCodeDialogConfirm"
                    >
                        {{ $t('pageKey411', '生成二维码') }}
                    </el-button>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <div class="qrcode-wrap">
                    <div
                        class="item"
                        v-if="qrCodeDialog.qrCodeImage">
                        <img
                            width="200"
                            :src="qrCodeDialog.qrCodeImage"
                            alt=""
                        >
                        <div class="label">{{ $t('pageKey414', 'h5二维码（右键另存为保存图片）') }}</div>
                    </div>
                    <div
                        class="item"
                        v-if="qrCodeDialog.minAppCodeImage">
                        <img
                            width="200"
                            :src="qrCodeDialog.minAppCodeImage"
                            alt=""
                        >
                        <div class="label">{{ $t('pageKey415', '小程序码（右键另存为保存图片）') }}</div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Goods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品货号
                goodsCatId: '', // 商品类目
                pointGoodsCatId: '', // 商品类目
                goodsStatus: '',
                isAvailable: '',
                taxRate: '',
                shopTagIdList: [],
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Gd.GoodsCat,
            selectApi1: this.$api.Sp.PointGoodsCat,
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品货号
                    goodsCatId: '', // 商品类目
                    goodsAgentNE: 1,
                    shopIdNull: 1,
                    goodsStatus: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
            },
            qrCodeDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    goodsId: '',
                    deviceId: '',
                },
                // 表单校验规则
                formRules: {},
                qrCodeImage: '',
                minAppCodeImage: '',
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.GoodsAgent.goodsAgentList({
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Gd.Goods.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onEdit(row) {
            this.$api.Gd.GoodsAgent.editGoodsAgent({
                id: row.id,
                isAvailable: row.isAvailable,
                orderNum: row.orderNum,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        onShowQrCode(row) {
            this.qrCodeDialog.formModel.goodsId = row.goodsId;
            this.$set(this.qrCodeDialog, 'qrCodeImage', null);
            this.$set(this.qrCodeDialog, 'minAppCodeImage', null);
            this.qrCodeDialog.isVisible = true;
        },
        onQrCodeDialogConfirm() {
            const { qrCodeForm } = this.$refs;
            qrCodeForm.validate().then(() => {
                this.$api.Gd.Goods.createGoodsQrCode({
                    ...this.qrCodeDialog.formModel,
                }).then(json => {
                    const res = json.data.data;
                    this.qrCodeDialog.qrCodeImage = res.qrCodeImage;
                    this.qrCodeDialog.minAppCodeImage = res.minAppCodeImage;
                });
            });
        },
        onQrCodeDialogCancel() {
            const { qrCodeForm } = this.$refs;
            qrCodeForm.resetFields();
            const { formModel } = this.qrCodeDialog;
            formModel.id = '';
            this.qrCodeDialog.isVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.qrcode-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .item {
        flex: 1 1 1%;
        width: 50%;

        > img {
            width: 200px;
            height: 200px;
            display: block;
            margin: 0 auto;
        }

        > .label {
            text-align: center;
            margin-top: $margin;
        }
    }
}
</style>
